import { Box, Grid, styled, Typography, useTheme } from "@mui/material";
import UnderlinedHeaderTextWord from "../../../../new_components/underlined-header-text-word";
import CheckedShield from "../../../../new_assets/CheckedShield";
import HandShake from "../../../../new_assets/HandShake";
import Lamp from "../../../../new_assets/Lamp";

const StyledHeadingContainer = styled(Grid)(({ theme }) => ({
  justifyContent: "space-between",
  alignItems: "flex-end",
  "@media(max-width: 1059px)": {
    gap: theme.spacing(3),
    flexDirection: "column",
    alignItems: "center",
  },
}));

const StyledHeading = styled(Typography)(({ theme }) => ({
  maxWidth: theme.spacing(48),
  fontSize: "clamp(2.5rem, -0.9091rem + 14.5455vw, 3rem)",
}));

const StyledDescription = styled(Typography)(({ theme }) => ({
  maxWidth: theme.spacing(79),
}));

const StyledDescriptionDetailsContainer = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(8),
  gap: theme.spacing(11),
  "@media(max-width: 1279px)": {
    gap: theme.spacing(1.5),
    justifyContent: "center",
  },
}));

const StyledDescriptionDetailsItem = styled(Grid)(({ theme }) => ({
  width: "100%",
  maxWidth: theme.spacing(46),
  minHeight: theme.spacing(40.5),
  borderLeftWidth: 1,
  borderLeftStyle: "solid",
  borderLeftColor: theme.palette.surface.border.main,
  paddingTop: theme.spacing(4),
  paddingLeft: theme.spacing(4),
  paddingBottom: theme.spacing(3),
  "@media(max-width: 1279px)": {
    borderLeft: "none",
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderBottomColor: theme.palette.surface.border.main,
    paddingTop: 0,
    paddingLeft: 0,
  },
}));

const iconSize = (theme) => ({
  width: theme.spacing(4),
  height: theme.spacing(4),
});

const AboutUs = () => {
  const theme = useTheme();

  return (
    <>
      <StyledHeadingContainer container>
        <StyledHeading variant="h2">Who are <br /><UnderlinedHeaderTextWord>Vegas
          Affiliate?</UnderlinedHeaderTextWord></StyledHeading>
        <StyledDescription variant="subtitle2">Based in St Albans, we're iGaming marketing specialists at Vegas
          Affiliate. With vast experience in affiliate marketing from both operator and affiliate
          perspectives, we understand the value of partnerships. Successful partnerships are fundamental to any
          great affiliate network, and our proven formula ensures success.</StyledDescription>
      </StyledHeadingContainer>
      <StyledDescriptionDetailsContainer container>
        <StyledDescriptionDetailsItem item display="flex" flexDirection="column" justifyContent="space-between">
          <CheckedShield sx={iconSize(theme)} />
          <Box display="flex" flexDirection="column" gap={1.5}>
            <Typography variant="subtitle1">Trust</Typography>
            <Typography variant="subtitle2">Prioritising transparent communication fosters crucial cooperation and
              investment in partnerships.</Typography>
          </Box>
        </StyledDescriptionDetailsItem>
        <StyledDescriptionDetailsItem item display="flex" flexDirection="column" justifyContent="space-between">
          <HandShake sx={iconSize(theme)} />
          <Box display="flex" flexDirection="column" gap={1.5}>
            <Typography variant="subtitle1">Adaptability</Typography>
            <Typography variant="subtitle2">Recognising the uniqueness of each affiliate, we tailor deals to their
              specific needs, ensuring mutually beneficial arrangements.</Typography>
          </Box>
        </StyledDescriptionDetailsItem>
        <StyledDescriptionDetailsItem item display="flex" flexDirection="column" justifyContent="space-between">
          <Lamp sx={iconSize(theme)} />
          <Box display="flex" flexDirection="column" gap={1.5}>
            <Typography variant="subtitle1">Dedication</Typography>
            <Typography variant="subtitle2">Our team is solely focused on making Vegas Affiliate the top affiliate
              programme, offering dedicated managers for personalised support.</Typography>
          </Box>
        </StyledDescriptionDetailsItem>
      </StyledDescriptionDetailsContainer>
    </>
  );
};

export default AboutUs;