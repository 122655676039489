import Header from "./components/header";
import { styled } from "@mui/material";
import HowItWorks from "./components/how-it-works";
import CommissionStructure from "./components/commission-structure";
import { StyledContainer } from "../../new_components/layouts/MainLayout";
import OurBrand from "./components/our-brand";
import AboutUs from "./components/about-us";
import GetInTouch from "./components/get-in-touch";

const StyledSection = styled("section")(({ theme }) => ({
  width: "100%",
}));

const PreFooterContent = styled(StyledSection)(({ theme }) => ({
  background: theme.palette.background.medium,
  borderRadius: theme.spacing(10),
  padding: theme.spacing(15.5, 0, 14),
  [theme.breakpoints.down("lg")]: {
    padding: theme.spacing(5, 1.5, 5),
  },
}));

const Home = () => {
  return (
    <>
      <StyledSection id="features">
        <StyledContainer container>
          <Header />
        </StyledContainer>
      </StyledSection>
      <StyledSection id="how-it-works-section">
        <StyledContainer container>
          <HowItWorks />
        </StyledContainer>
      </StyledSection>
      <StyledSection id="benefits">
        <CommissionStructure />
      </StyledSection>
      <StyledSection id="our-brand">
        <OurBrand />
      </StyledSection>
      <PreFooterContent>
        <StyledSection id="about">
          <StyledContainer container>
            <AboutUs />
          </StyledContainer>
        </StyledSection>
        <StyledSection id="contact">
          <StyledContainer container>
            <GetInTouch />
          </StyledContainer>
        </StyledSection>
      </PreFooterContent>
    </>
  );
};

export default Home;