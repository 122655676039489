import { useEffect, useState } from "react";
import axios from "axios";
import "./Terms.scss";
import ScrollButton from "../../components/ScrollButton/ScrollButton";
// import { toastApiError } from "../../utils/toastApiError";


const Terms = () => {
  const [data, setData] = useState(null);

  const fetchTermsData = async () => {
    try {
      const response = await axios.get(
        //TODO make this work
        `/api/v2/article?key=terms`,
      );
      if (response) {
        setData(response.data.data.attributes.value);
      }
    } catch (err) {
      //TODO uncomment when api call starts work (with import above)
      //toastApiError(err);
    }
  };

  useEffect(() => {
    fetchTermsData();
  }, []);

  return (
    <div id="container">
      <section id="terms-top" className="terms_section">
        <div className="innerWrap">
          <h2>Vegas Affiliate Terms and Conditions</h2>
        </div>
      </section>

      <section id="main" className="content terms_section__container">
        <div className="innerWrap terms-wrap terms_section__box">
          <h6><b>INTRODUCTION</b></h6>

          <p>By accepting the Terms and Conditions set out below, you agree to initiate a connection between the
            vegasaffiliate.com website and your site(s), using trackable promotional materials provided by us in return
            for the agreed benefit.</p>

          <h6><b>1. SIGNING UP TO THE AFFILIATE PROGRAMME</b></h6>

          <p>To become an affiliate of the Vegas Affiliate Programme, you should register as an affiliate by completing
            the online application form. The information you provide in your online application form must be genuine and
            complete to be considered for approval. We may decide not to accept your request if it is deemed incomplete,
            irrelevant, or not genuine in any aspect.</p>
          <p>Should your application be approved, you will be informed via a confirmation email sent to the email
            address you provided in your application form, along with a username and a password. These credentials are
            yours and should not be provided to any third party. The username and password are unique to you and ensure
            easy access to your Affiliate panel, allowing you to choose banners from our gallery or use our reporting
            tool to track your commission growth.</p>
          <p>We may refuse to approve your application to join the affiliate programme.</p>

          <h6><b>2. OUR RIGHTS AND OBLIGATIONS</b></h6>

          <p>Always subject to Clause 3.1:</p>
          <ul>
            <li>We will register customers and track their transactions. Once registered, the customer becomes our
              customer and must accept all our rules, policies, and operating procedures.
            </li>
            <li>We will pay you commission in accordance with Clause 4 below.</li>
            <li>We will use reasonable endeavors to provide you with password-protected access to a report in the
              Affiliate Area showing the Net Revenue of Customers across Our Products.
            </li>
            <li>We will use reasonable endeavors to tag the identity of all Visitors, record all bets and stakes made by
              customers, and record the history of commission payments made to you.
            </li>
          </ul>

          <p>IF YOU DECIDE NOT TO ACCEPT ALL OF THE TERMS AND CONDITIONS OF THIS AGREEMENT, PLEASE DO NOT DOWNLOAD
            BANNER CODE, TEXT LINK CODE, PROMOTIONAL COPY, OR ANY INFORMATION PERTAINING TO VEGAS AFFILIATE.</p>
          <p>We reserve the right to terminate this Agreement if we determine that your site is unsuitable. Unsuitable
            sites may include those that promote sexually explicit materials, are targeted towards children or minors,
            promote violence, promote discrimination based on race, sex, religion, nationality, disability, sexual
            orientation, promote illegal activities, or violate intellectual property rights (“IP rights”).</p>
          <p>This Agreement sets all the terms and conditions of membership to the Vegas Affiliate Programme. “You,” the
            “affiliate,” refers to you, the individual, group, or corporate entity registering with us as an affiliate
            under this Agreement, and will be deemed to include any employees, officers, directors, shareholders,
            owners, controlling parties, and affiliated individuals and entities (and “your” has a corresponding
            meaning).</p>

          <h6><b>3. AFFILIATE RIGHTS AND OBLIGATIONS</b></h6>

          <p>As an affiliate, you can open and run only one affiliate account; odd accounts will be closed at our sole
            discretion. As an Affiliate, you are responsible for promoting Vegas Affiliate by implementing the
            advertising, banners, and tracking URLs on your websites, e-mails, or other communications.</p>
          <ul>
            <li>You will agree to register and maintain correct and truthful contact information with Vegas Affiliate.
            </li>
            <li>You will present only content and topics on your site which are pre-approved in writing by Vegas
              Affiliate. Content that is deemed unsuitable at Vegas Affiliate’s sole discretion will result in the
              affiliate agreement being terminated immediately. Content is deemed unsuitable in cases where it is aimed
              at individuals under the age of 18 years.
            </li>
            <li>You will be solely responsible for the development, operation, and maintenance of your site and for all
              materials that appear on your site and at your own cost. For example, you will be solely responsible for
              ensuring that materials posted on your site are not libelous or otherwise illegal or infringe the IP
              rights of third parties. Since we do not have control over your site, we disclaim all liability for these
              matters. Furthermore, you will indemnify and hold us harmless from all claims, damages, and expenses
              (including, without limitation, attorney’s fees) relating to the development, operation, maintenance, and
              contents of your site. This provision on indemnification is without prejudice to our separate action or
              claim against you under applicable laws.
            </li>
          </ul>

          <h6><b>Affiliation Guidelines</b></h6>

          <ul>
            <li>Only approved and properly tagged creative materials, supplied by us from time to time, may be used to
              promote Vegas Affiliate. Advertorials and personal endorsements are allowed, but all materials not
              designed by Vegas Affiliate must be approved in writing; such approval shall not be unreasonably withheld.
            </li>
            <li>You may not modify or use for any other purpose any IP rights, banners, or other creative material
              supplied by us without the prior written consent of Vegas Affiliate. All copyright or IP rights notices on
              any material supplied or approved by Vegas Affiliate must remain and not be modified or eliminated.
            </li>
            <li>By agreeing to participate in the Vegas Affiliate Programme, you are agreeing to download banners, text,
              or promotional material and place it on your site, utilize it within e-mail, direct marketing using your
              affiliate URL, or print. These methods are the only approved methods by which you may advertise Vegas
              Affiliate's products and services.
            </li>
            <li>Banners and links may not be placed within unsolicited e-mail, unauthorized newsgroup postings, or chat
              rooms or using “bots”. Traffic generated illegally will not be counted and may result in the termination
              of your affiliate account with us.
            </li>
            <li>We will terminate this Agreement immediately if there is any form of spamming on your behalf or if you
              discredit Vegas Affiliate through false advertising, written or uttered words.
            </li>
            <li>Any claims, representations, or warranties in connection with Vegas Affiliate are prohibited and are not
              binding on Vegas Affiliate or to any obligations.
            </li>
            <li>Without our prior written approval, you will only use our approved banners and links and will not alter
              their appearance nor refer to us in any promotional materials. The appearance and syntax of the hypertext
              transfer links are designed and designated by us and constitute the only authorized and permitted
              representation of Vegas Affiliate.
            </li>
            <li>You will not knowingly benefit from known or suspected traffic not generated in good faith whether or
              not it actually causes Vegas Affiliate damage. Should fraudulent activity arise through a person directed
              to a site via your link, we retain the right to retract the commissions paid to you at any time. Our
              decision in this regard will be final and no correspondence will be entered. We reserve the right to
              retain all amounts due to you under this Agreement if we have reasonable cause to believe that such
              traffic has been caused with your knowledge. Even if you have not knowingly generated such traffic, we
              reserve the right to withhold affiliate commissions with respect to such.
            </li>
            <li>Affiliates are prohibited from playing at any of the sportsbook/casino/games promoted by Vegas Affiliate
              through their own affiliate link or from the affiliate link of an associate who shares all, or part of the
              commission with the affiliate or a third party. This includes any individuals or playing groups from which
              an Affiliate receives compensation or remuneration of any kind. In the event of violation of this section,
              Vegas Affiliate and the respective website that has been played with have the right to void all play and
              eliminate any earned commissions applicable from such play and eliminate the affiliate from our Affiliate
              Programme, terminating this Agreement and voiding any future play at Vegas Affiliate.
            </li>
          </ul>

          <h6><b>4. COMMISSIONS AND PAYMENTS</b></h6>

          <p>Vegas Affiliate will pay out a commission, as defined by the Vegas Affiliate Programme commission
            structure, if your account maintains active monthly. In cases where there are no new active wagering members
            within a six-month period, your account might be terminated with prior notification, and all monthly
            commissions generated moving forward might be revoked.</p>

          <ol>
            <li>Invoices must be received between the 7th and 30th day of every month; failure to follow this procedure
              will result in the said invoice being settled in the next payment cycle.
            </li>
            <li>Active Wagering Members refers to referrals that have performed a minimum deposit of 10 EUR or
              equivalent (for e-wallet deposits), 25 EUR or equivalent (for credit card deposits), and 100 EUR (for bank
              wires).
            </li>
            <li>Commissions will be calculated only at the end of the month and according to the total revenues
              generated during the given month. Commissions are paid once per month only.
            </li>
            <li>Commissionable Earnings are calculated as Vegas Affiliate's profit, less charge-backs, complimentary
              money, free money offers, and other incentives offered to the customer.
              <ul>
                <li>Charge-back: A credit card holder discovers irregular transactions made on his/her Credit/Debit
                  Card, which were not authorized by him/her. The credit card holder then requests his/her bank to
                  reverse these charges. Charge-backs relate to fraudulent use by a third party of the credit card
                  holder’s card or card number.
                </li>
                <li>Should the player process a charge-back, the disputed or charged-back revenue generated by yourself
                  will be forfeited and therefore deducted from the total balance due to you for the current month.
                </li>
                <li>Should this deduction of the accumulated revenue exceed your current amount due, your balance will
                  then revert to a negative balance, and you will have to earn revenue to cover the charge-back before
                  you can start earning revenue again.
                </li>
                <li>Unlike a player making a big winning, which only lasts one month, a charge-back will stay due until
                  the revenue generated by your other players has covered the amount due.
                </li>
                <li>We understand that this might be frustrating to affiliates, but it must be made clear that Vegas
                  Affiliate can only pay out a percentage of their profits, not fraudulent revenue.
                </li>
              </ul>
            </li>
            <li>Complimentary Money, Free Money, and Other incentives refer to those amounts credited to the account of
              customers. As they have not purchased these amounts, Vegas Affiliate cannot pay commissions on these
              amounts.
            </li>
            <li>Should you fail to be entitled to the Commissionable Earnings in accordance with Clauses 4.1, 4.2, and
              4.3 above, you may be given a grace period of two months within which to comply. Upon compliance, your
              commission for the subject period, not exceeding three consecutive months, will be consolidated to
              constitute your commissionable earnings for the same period (at Vegas Affiliate’s sole discretion).
            </li>
            <li>Commissionable earnings will be subject to commissions as calculated using the Vegas Affiliate Programme
              commission structure in force from time to time.
            </li>
            <li>Negative earnings are carried forward to the next month. Should the affiliate close the month with a
              positive balance, payment will be issued if it meets the minimum requirements of 500 EUR, and commissions
              will be paid on the positive balance. If an affiliate has a negative balance, they will have to generate
              enough commissions in the following month to cover the absolute negative amount plus the minimum
              requirement of 500 EUR to get paid.
            </li>
            <li>The Commissionable Earnings of the affiliate calculated based on the commission structure will be earned
              on all transactions that the customer undertakes with the merchant if the affiliate remains a member of
              this affiliate programme.
            </li>
            <li>Player(s) referred to our domains by any affiliate and who either self-exclude voluntarily shall, at the
              Company’s discretion, be excluded when calculating commissions due to the particular affiliate who
              introduced the player to our website.
            </li>
            <li>Revenue Share Plan:
              <ul>
                <li>First-time deposit players Amount of RS:
                  <ul>
                    <li>0-40: 35%</li>
                    <li>41+: 40%+</li>
                  </ul>
                </li>
              </ul>
            </li>
          </ol>

          <p>Contact your affiliate manager to discuss a custom deal.</p>

          <h6><b>8.8 Payments</b></h6>

          <p>8.8.1 The affiliate acknowledges that payments made by Vegas Affiliate will only occur when the threshold
            of 500 EUR is met and confirmed by the account manager.</p>
          <p>8.8.2 The affiliate acknowledges that payments will occur after Net (60) days after calendar end.</p>
          <p>8.8.3 Unless negotiated otherwise, negative revenue will be carried over to the following month until
            profits surpass the revenue loss.</p>
          <p>8.8.4 An FTD will not be considered valid for CPA commission for the following reasons: the user is already
            an existing player at another brand within the Vegas Affiliate portfolio.</p>
          <p>8.8.5 Unless agreed otherwise, CPA commission will only be payable when the FTD count has entirely
            fulfilled the agreed-upon player test.</p>
          <p>8.8.6 In the following circumstances, an FTD will not be considered valid for CPA commission but will
            revert to the default revshare commission of 35%:</p>
          <ul>
            <li>the FTD occurs after the initial player cap has been reached and a new player cap has not been agreed.
            </li>
            <li>the user is already an existing player at another brand within the Vegas Affiliate portfolio.</li>
            <li>the user is not located from a pre-approved GEO.</li>
            <li>the user self-excludes within 7 days of their first deposit.</li>
          </ul>
          <p>8.8.7 In the following circumstances, an FTD will not be considered valid for any commission:</p>
          <ul>
            <li>the user is flagged as a ‘Bonus abuser’ within the Vegas Affiliate system.</li>
            <li>the user fails ‘KYC’ checks.</li>
          </ul>
          <p>8.8.8 Any payments that constitute listing fees, CPA, or CPC fees must result in a minimum return on
            investment (ROI) of 40%
            by the time the payment is due, which is 60 days after the end of the month in which the relevant players
            were brought.
            If this ROI threshold is not met, the company reserves the right to review the agreement terms.</p>

          <h6><b>8.9 No Third Parties</b></h6>
          <p>A person who is not a party to this Agreement has no right under the Contracts (Rights of Third Parties)
            Act 1999 to enforce or to enjoy the benefit of any term of this Agreement; provided, however, that such
            exclusion as provided by this clause shall in no case apply to any member of the Group where any or all of
            the affiliate's breaches of this Agreement cause or can potentially cause damage, regulatory fine or other
            sanction, or the like, to such Group company(ies).</p>

          <h6><b>8.10</b></h6>
          <p>In case you earn a revenue share commission generated from the promotion of multiple brands within the
            affiliate program, the commission will be calculated individually for each brand and then combined together
            to determine the total commission earned.</p>

          <h6><b>8.11 Effect of This Agreement</b></h6>
          <p>This Agreement constitutes the entire agreement and understanding between the affiliate and Vegas
            Affiliate. This Agreement supersedes all previous agreements, representations or arrangements whether made
            orally or in writing.</p>

          <h6><b>POLICIES & CONFIDENTIALITY</b></h6>

          <p>Vegas Affiliate assumes ownership of the customer at the point of first contact with the customer. You, as
            an affiliate, act as a referring agent for Vegas Affiliate. We reserve the right to refuse customers (or to
            close their accounts) if necessary, to comply with any requirements we may periodically establish.</p>
          <p>By opening an account with a customer, that person or entity will become our customer, and, accordingly,
            all client rules, policies, and operating procedures will apply to them.</p>
          <p>During the term of this Agreement, you may be entrusted with confidential information relating to the
            business, operations, or underlying technology of our affiliate programme (including, for example, referral
            fees earned by you under the programme). You agree not to disclose or use the confidential information to
            third persons or outside parties unless you have our prior written consent and that you will use the
            confidential information only for purposes necessary to further the purposes of this Agreement. Your
            obligations with respect to confidential information will survive the termination of this Agreement.</p>

          <h6><b>LEGAL RESPONSIBILITIES</b></h6>

          <p>Ownership and content of our sites remain our respective properties and shall not be deemed to have been
            transferred to the affiliate through any act or omission concerning the affiliation Agreement.</p>
          <p>Ownership, content, and liability for affiliate sites are the sole responsibility of the affiliate. You
            will be solely responsible for the development, operation, and maintenance of your site and for all
            materials that appear on your site and at your own cost.</p>
          <p>You will indemnify and hold us unaccountable for all claims, damages, and expenses (including, without
            limitation, attorneys’ fees) relating to the development, operation, maintenance, and contents of your site.
            This provision on indemnification is without prejudice to our separate action or claim against you under
            applicable laws.</p>
          <p>It is the affiliates’ responsibility to follow the correct linking and tagging procedure to ensure new
            customer tracking and payment.</p>
          <p>Presentation of our banners & content on the affiliate’s site is the responsibility of the affiliate. The
            affiliate must ensure that our content is presented in accordance with our outlines.</p>
          <p>The affiliate must ensure that any material posted on their site is legal and does not infringe copyright
            or violate any unlawful rights.</p>
          <p>We may modify any of the terms and conditions contained in this Agreement, at any time and at our sole
            discretion, by posting a change notice or a new agreement on our site. Modifications may include, for
            example, changes in the scope of available referral fees, fee schedules, payment procedures, and referral
            programme rules, and notifying you via the Affiliate programme's backend. IF ANY MODIFICATIONS ARE
            UNACCEPTABLE TO YOU, YOUR ONLY RECOURSE IS TO TERMINATE THIS AGREEMENT. YOUR CONTINUED PARTICIPATION IN THE
            PROGRAMME FOLLOWING OUR POSTING OF A CHANGE NOTICE OR NEW AGREEMENT ON OUR SITE WILL CONSTITUTE BINDING
            ACCEPTANCE OF THE CHANGE.</p>
          <p>We do not condone Spam!</p>
          <p>Any form of Spam will result in your account being placed under review and all funds due will be withheld
            pending an investigation into your account. Be aware that our clients are liable to incur expenses in
            dealing with Spam-generated mail and these same expenses will be deducted from your account should our
            client seek recourse. In this instance, the amount determined by the relative client will be fair and deemed
            final and acceptable based on good faith and such an amount will be collectible by law and deemed to have
            been accepted by you as fair and reasonable and as agreed to by registration as an affiliate of Vegas
            Affiliate.</p>
          <p>Should these expenses not be covered by funds in your account, we reserve the right to investigate other
            alternative means for obtaining payment, for example, should your account have generated purchasing
            accounts, we hold the right to withhold payments of commission for these accounts until the account for
            damages has been cleared. Should your account not be active and be generating profit through commission
            payments, we reserve the right to demand payments from the account holder.</p>
          <p>You and Vegas Affiliate are independent contractors, and nothing in this Agreement will create any
            partnership, joint venture, agency, franchise, sales representative, or employment relationship between the
            parties. You will have no authority to make or accept any offers or representations on our behalf. You will
            not make any statement, whether on your site or otherwise, that reasonably would contradict anything in this
            paragraph.</p>

          <h6><b>8. MISCELLANEOUS</b></h6>

          <h6><b>Assignability and Inurement</b></h6>
          <p>You may not assign this Agreement, by operation of law or otherwise, without our prior written consent.
            Subject to that restriction, this agreement will be binding on, inure to the benefit of, and enforceable
            against you and us and our respective successors and assigns.</p>

          <h6><b>Non-Waiver</b></h6>
          <p>Our failure to enforce your strict performance of any provision of this Agreement will not constitute a
            waiver of our right to subsequently enforce such provision or any other provision of this Agreement. NO
            MODIFICATIONS, ADDITIONS, DELETIONS, OR INTERLINEATIONS OF THIS AGREEMENT ARE PERMITTED OR WILL BE
            RECOGNIZED BY US. None of our employees, officers, or agents may verbally alter, modify, or waive any
            provision of this Agreement.</p>

          <h6><b>Remedies</b></h6>
          <p>Our rights and remedies hereunder shall not be mutually exclusive, i.e., the exercise of one or more of the
            provisions of this Agreement shall not preclude the exercise of any other provision. You acknowledge,
            confirm, and agree that damages may be inadequate for a breach or a threatened breach of this Agreement and,
            in the event of a breach or threatened breach of any provision of this Agreement, the respective rights and
            obligations of the parties may be enforceable by specific performance, injunction, or other equitable
            remedy. Nothing contained in this Agreement will limit or affect any of our rights at law, or otherwise, for
            a breach or threatened breach of any provision of this agreement, it being the intent of this provision to
            make clear that our respective rights and obligations shall be enforceable in equity as well as at law or
            otherwise.</p>

          <h6><b>Severability / Waiver</b></h6>
          <p>Whenever possible, each provision of this agreement will be interpreted in such a manner as to be effective
            and valid under applicable law, however, if any provisions of this agreement are held to be invalid,
            illegal, or unenforceable in any respect, such provision will be ineffective only to the extent of such
            invalidity, or unenforceability, without invalidating the remainder of this agreement or any provision
            hereof. No waiver will be implied from conduct or failure to enforce any rights and must be in writing to be
            effective.</p>

          <h6><b>TERM & TERMINATION</b></h6>
          <p>The term of this agreement will begin when you download linking code and link it to our site and will be
            continuous unless and until either party notifies the other in writing that it wishes to terminate the
            Agreement, in which case this agreement may be terminated immediately. TERMINATION IS AT WILL, FOR ANY
            REASON, BY EITHER PARTY. For purposes of notification of termination, delivery via e-mail is considered a
            written and immediate form of notification.</p>
          <p>In the event of termination of this Agreement:</p>
          <ul>
            <li>You must remove Vegas Affiliate's banner(s) from your site and disable any links from your site to Vegas
              Affiliate.
            </li>
            <li>All rights given to you in this agreement are immediately terminated.</li>
            <li>You will be entitled only to those unpaid referral fees, if any, earned by you on or prior to the date
              of termination, save where termination is a result of your breach of terms of this Agreement, as detailed
              at 9.2.d below. We may withhold your final payment for a reasonable amount of time to ensure that the
              correct amount is paid.
            </li>
            <li>If you have failed to fulfill your obligations and responsibilities, Vegas Affiliate reserves the right
              not to pay you the referral fees otherwise owing to you on termination.
            </li>
            <li>We may withhold your final payment for a reasonable amount of time (90 days) to ensure that the correct
              amount is paid.
            </li>
            <li>If we continue to permit activity (generation of revenue) from customers after termination, this will
              not constitute a continuation or renewal of this agreement or a waiver of termination.
            </li>
            <li>You will and are obligated to return to us any confidential information and all copies of it in your
              possession, custody, and control and will cease all uses of any trade names, trademarks, service marks,
              logos, and other designations of the merchants.
            </li>
            <li>You and Vegas Affiliate will be released from all obligations and liabilities to each other occurring or
              arising after the date of such termination, except with respect to those obligations that by their nature
              are designed to survive termination, as set out in this Agreement. Termination will not relieve you from
              any liability arising from any breach of this Agreement, which occurred prior to termination.
            </li>
          </ul>
          <p>In the event of any conflict between the terms and conditions outlined in this agreement and any Insertion
            Orders,
            Agreements, or Terms signed with third parties, the terms of this agreement shall supersede and take
            precedence
            over the third-party terms in case of any discrepancies or conflicts.</p>

          <h6><b>EXCLUDED TERRITORIES</b></h6>
          <p>You will not market or promote any Site within or to persons from any Excluded Territories; or be involved
            in any traffic coming from any Excluded Territories; or allow, assist, or encourage circumvention of any
            restriction put in place by us and/or any Site in connection with Excluded Territories. “Excluded
            Territories” include the territories indicated in the list below, which may be changed by us from time to
            time. IT IS YOUR DUTY TO CONSULT AND CHECK REGULARLY THIS AGREEMENT REGARDING ANY CHANGES TO THIS LIST.</p>
          <p>The Excluded Territories are as follows:</p>
          <p>Afghanistan, Aland Islands, American Samoa, Antigua and Barbuda, Aruba, Australia, Bonaire, British Indian
            Ocean Territory, Bulgaria, Cyprus, Curaçao, France, French Guiana, French Polynesia, French Southern
            Territories, Hong Kong, Indonesia, Iran, Iraq, Israel, Korea (North), Kosovo, Lebanon, Lithuania, Macau,
            Netherlands, Netherlands Antilles, Neutral Zone, New Caledonia, Nicaragua, Panama, Qatar, Romania, Saba,
            Samoa, Spain, Statia, St Maarten, United Kingdom, United States, Vatican City State (Holy See), Virgin
            Islands (British), Virgin Islands (U.S.), Wales, Western Sahara, Yemen.</p>

          <h6><b>11. RELATIONSHIP OF PARTIES</b></h6>
          <p>You and Vegas Affiliate are independent contractors, and nothing in this agreement will create any
            partnership, joint venture, agency, franchise, sales representative, or employment relationship between the
            parties. You will have no authority to make or accept any offers or representations on our behalf. You will
            not make any statement, whether on your site or otherwise, to contradict anything in this paragraph or be
            reasonably deemed to contradict this paragraph.</p>

          <h6><b>INDEMNITY</b></h6>
          <p>You hereby agree to indemnify and hold not liable Vegas Affiliate, the entities it represents and
            affiliates, and Vegas Affiliate's directors, officers, employees, agents, shareholders, partners, members,
            and other owners, against any and all claims, actions, demands, liabilities, losses, damages, judgments,
            settlements, expenses (including reasonable attorneys’ fees), and costs (any or all of the foregoing
            hereinafter referred to as “Losses”) insofar as such Losses (or actions in respect thereof) arise out of or
            are based on:</p>
          <ul>
            <li>Any claim that our use of the Affiliate Trademarks infringes on any trademark, trade name, service mark,
              copyright, license, intellectual property, or other proprietary right of any third party,
            </li>
            <li>Any misrepresentation of a representation or warranty or breach of a covenant and agreement made by you
              herein,
            </li>
            <li>The development, operation, maintenance, and content of your site and products and services offered from
              your site,
            </li>
            <li>Any claim related to your site, including, without limitation, content therein not attributable to us.
            </li>
          </ul>

          <h6><b>DISCLAIMER</b></h6>
          <p>We make no express or implied warranties or representations with respect to the referral programme or
            referral fee payment arrangements (including, without limitation, their functionality, warranties of
            fitness, merchantability, legality, non-infringement, or any implied warranties arising out of a course of
            performance, dealing, or trade usage). In addition, we make no representation that the operation of our site
            will be uninterrupted or error-free and will not be liable for the consequences of any interruptions or
            errors.</p>

          <h6><b>LIMITATION OF LIABILITY</b></h6>
          <p>We are not liable for any indirect, special, or consequential damages (or any loss of revenue, profits, or
            data) arising in connection with this agreement or the referral programme, even if we have been advised of
            the possibility of such damages. Furthermore, our aggregate liability arising with respect to this agreement
            and the programme will not exceed the total commissions paid or payable to you under this agreement. Nothing
            in this agreement is to be construed to provide any rights, remedies, or benefits to any person or entity
            not a party to this agreement. Our obligations under this agreement do not constitute personal obligations
            of our directors, officers, or shareholders. Any liability arising under this agreement shall be satisfied
            solely from the referral fee generated and is limited to direct damages.</p>

          <h6><b>INDEPENDENT INVESTIGATION</b></h6>
          <p>YOU ACKNOWLEDGE THAT YOU HAVE READ THIS AGREEMENT AND AGREE TO ALL ITS TERMS AND CONDITIONS. YOU UNDERSTAND
            THAT WE MAY AT ANY TIME (DIRECTLY OR INDIRECTLY) SOLICIT CUSTOMER REFERRALS ON TERMS THAT MAY DIFFER FROM
            THOSE CONTAINED IN THIS AGREEMENT OR OPERATE WEB SITES THAT ARE SIMILAR TO OR COMPETE WITH YOUR WEB SITE.
            YOU HAVE INDEPENDENTLY EVALUATED THE DESIRABILITY OF PARTICIPATING IN THIS AFFILIATE PROGRAMME AND ARE NOT
            RELYING ON ANY REPRESENTATION, GUARANTEE, OR STATEMENT OTHER THAN AS SET FORTH IN THIS AGREEMENT.</p>

          <h6><b>IN WITNESS WHEREOF</b></h6>
          <p>By having read the terms and conditions, and acknowledging such in the affiliate sign-up form, you agree to
            all the terms and conditions contained herein. Vegas Affiliate reserves the right to withhold payment from
            any affiliate that violates any of the terms and conditions contained herein.</p>
        </div>
      </section>
      <ScrollButton/>
    </div>
  );
};

export default Terms;
