import { styled, Typography } from "@mui/material";
import CardsDataContainer from "../../../../new_components/cards-data-container";
import BrandSlider from "./brand-slider";
import { DataRowContainer } from "../../../../new_components/data-row-container";
import { DescriptionSide } from "../../../../new_components/data-row-container/description-side";
import UnderlinedHeaderTextWord from "../../../../new_components/underlined-header-text-word";
import OrangeArrowButton from "../../../../new_components/buttons/orange-arrow-button";
import {Link} from "react-router-dom";
import PureBetsPic from "../../../../new_assets/images/pure-bets-pic.png";
import CasinoloPic from "../../../../new_assets/images/casinolo-pic.png";
import PureBetsIcon from "../../../../new_assets/PureBetsIcon";
import CasinoloIcon from "../../../../new_assets/CasinoloIcon";
import {useState} from "react";

const OurBrandContainer = styled(DataRowContainer)(({ theme }) => ({
  padding: theme.spacing(6, 0, 11),
  [theme.breakpoints.down("lg")]: {
    padding: 0,
    flexDirection: "column-reverse",
  },
}));

const brands = [
    {
        picture: PureBetsPic,
        icon: <PureBetsIcon sx={{ fontSize: 214, height: 58 }} />,
        description: <Typography variant="subtitle2">
            PureBets is a Curacao-licensed brand. We offer top-notch selection of casino slots, live casino, sports, and live betting.
        </Typography>,
        url: "https://purebets.com"
    },
    {
        picture: CasinoloPic,
        icon: <CasinoloIcon sx={{ fontSize: 214, height: 58 }} />,
        description: <Typography variant="subtitle2">
            Casinolo is online casino brand operating under an Anjouan license. With a vast selection of over 77 game providers, we offer top-tier slots, live casino games, table games, and sports betting. Enjoy fast payouts, 24/7 live support, and exciting bonuses, including a 100% welcome offer up to €500 + 200 free spins.
        </Typography>,
        url: "https://casinolo.com"
    }
]

const OurBrand = () => {
  const [currentBrand, setCurrentBrand] = useState(brands[0]);

  const onSlideChange = (slideIndex) => {
      setCurrentBrand(brands[slideIndex]);
  }

  return (
    <OurBrandContainer container>
      <CardsDataContainer>
        <BrandSlider brands={brands} onSlideChange={onSlideChange}/>
      </CardsDataContainer>
      <DescriptionSide gap={3} alignItems="flex-start">
        <Typography variant="h2">Our <UnderlinedHeaderTextWord>Brands</UnderlinedHeaderTextWord></Typography>
          { currentBrand.description }
        <OrangeArrowButton component={Link} to={currentBrand.url}>Visit Website</OrangeArrowButton>
      </DescriptionSide>
    </OurBrandContainer>
  );
};

export default OurBrand;