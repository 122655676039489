import Title from "../../components/Title/Title";
import { Col, Container, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { Route, Routes } from "react-router";
import { UserSettings } from "../../components/UserSettings";
import { HistoricPayments } from "../../components/HistoricPayments";
import { PaymentSettings } from "../../components/PaymentSettings";
import "./Profile.scss";
import PasswordReset from "../../components/PasswordReset/PasswordReset";
import {useEffect, useState} from "react";
import {affiliateApi} from "../../api/affiliateApi";
import {Box, Grid, Stack, TextField, Typography} from "@mui/material";

const Profile = () => {
  const [contact, setContact] = useState();

  useEffect(() => {
    affiliateApi.getManagersContact().then(c => setContact(c))
  }, []);

  return (
    <>
      <Title title="Profile info" />
      <Container>
        <Row>
          <Col lg="4">
            <div className="data__wrapper">
              <NavLink
                to="/admin/profile/user-settings"
                className="toggleSettings"
              >
                User Settings
              </NavLink>
              <NavLink
                to="/admin/profile/payment-settings"
                className="toggleSettings"
              >
                Payment Settings
              </NavLink>
              <NavLink
                to="/admin/profile/historic-payments"
                className="toggleSettings"
              >
                Historic Payments
              </NavLink>
              <NavLink
                to="/admin/profile/password-reset"
                className="toggleSettings"
              >
                Change Password
              </NavLink>
            </div>
            <div className="data__wrapper">
              <center><h4>Your affiliate Manager</h4></center>
              <div className="profile">
                <hr/>
                <div className="profile__descr">
                  <a href="mailto:affiliates@winningpartner.com">
                    <p className="profile__text">
                      {contact && contact.email}
                    </p>
                  </a>
                  <Stack gap={2}>
                    {contact && contact.contacts
                        .map((c) => <Stack>
                          <Typography sx={{ textAlign: 'start' }}>{c.platform}</Typography>
                          <TextField disabled defaultValue={c.contact}/>
                        </Stack>)
                    }
                  </Stack>
                </div>
              </div>
            </div>
          </Col>
          <Col lg="8">
            <div className="data__wrapper">
              <Routes>
                <Route path="user-settings" element={<UserSettings/>}/>
                <Route path="payment-settings" element={<PaymentSettings/>}/>
                <Route path="historic-payments" element={<HistoricPayments/>}/>
                <Route path="password-reset" element={<PasswordReset/>}/>
              </Routes>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Profile;
