import { useEffect, useState } from "react";
import { Dashboard } from "../../components/Dashboard/Dashboard";
import { Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import DataTable from "react-data-table-component";
import { Circular } from "../../components/Circular/Circular";
import connectTopLine from "../../components/HOC/connectTopLine";
import Search from "../../components/Search/Search";
import Export from "../../components/Export/Export";
import { applyCurrencyFormatters } from "../../common/helper";
import "./DynamicVariables.scss";
import fetchData from "../../api/data";
import { toastApiError } from "../../utils/toastApiError";
import useAuth from "../../auth/useAuth";
import {CircularProgress} from "@mui/material";

const customStyles = {
  header: {
    style: {
      fontSize: "24px",
      fontWeight: "800",
    },
  },
  headCells: {
    style: {
      fontSize: "14px",
      textAlign: "center",
    },
  },
};

const perPageList = [10, 50, 100];

const DynamicVariables = ({ datesFilter, brandsFilter }) => {
  const auth = useAuth();
  const [reports, setReport] = useState([]);
  const [secondReports, setSecondReport] = useState([]);
  const [stats, setStats] = useState({});
  const [search, setSearch] = useState("");
  const [secondSearch, setSecondSearch] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const query = search ? `search=` + encodeURIComponent(search) : "";
    const fetchUrl = `/api/v3/reports/variables?${datesFilter}&${brandsFilter}&${query}`;

    setLoading(true);
    return fetchData({
      url: fetchUrl,
      onSuccess: (response) => {
        setReport(response.data.reports);
        setStats(response.data.totalsReport);
      },
      onError: toastApiError,
      onCallback: () => setLoading(false),
    });
  }, [search, datesFilter, brandsFilter]);

  useEffect(() => {
    const query = search ? `search=` + encodeURIComponent(search) : "";
    const fetchUrl = `/api/v3/reports/second/variables?${datesFilter}&${brandsFilter}&${query}`;

    setLoading(true);
    return fetchData({
      url: fetchUrl,
      onSuccess: (response) => {
        setSecondReport(response.data.reports);
      },
      onError: toastApiError,
      onCallback: () => setLoading(false),
    });
  }, [secondSearch, datesFilter, brandsFilter]);

  const columns = [
    {
      name: "Dynamic variable",
      selector: (row) => row.variable,
      sortable: true,
      grow: 3,
      style: {
        minWidth: "240px",
        fontSize: "14px",
      },
    },
    {
      name: "Brand",
      selector: (row) => row.brandName,
      sortable: true,
      style: {
        minWidth: "120px",
        fontSize: "14px",
      },
    },
    {
      name: "Date",
      selector: (row) => row.date,
      sortable: true,
      style: {
        width: "140px",
        fontSize: "14px",
      },
    },
    {
      name: "Clicks",
      selector: (row) => row.clicks,
      sortable: true,
      right: true,
    },
    {
      name: "Registrations",
      selector: (row) => row.registrations,
      sortable: true,
      right: true,
    },
    {
      name: "FTDs",
      selector: (row) => row.ftds,
      sortable: true,
      right: true,
    },
    {
      name: "No of deposits",
      selector: (row) => row.numberOfDeposits,
      sortable: true,
      right: true,
      omit: !auth.user.authorities.includes('VIEW_DEPOSITS')
    },
    {
      name: "Deposits",
      selector: (row) => row.deposits,
      sortable: true,
      format: "currency",
      right: true,
      omit: !auth.user.authorities.includes('VIEW_DEPOSITS')
    },
    {
      name: "Net Cash",
      selector: (row) => row.netCash,
      sortable: true,
      format: "currency",
      right: true,
      omit: !auth.user.authorities.includes('VIEW_NET_CASH')
    },
    {
      name: "Rev Share Commission",
      selector: (row) => row.revShareCommission,
      sortable: true,
      format: "currency",
      right: true,
    },
    {
      name: "CPA Commission",
      selector: (row) => row.cpaCommission,
      sortable: true,
      format: "currency",
      right: true,
    },
  ];

  const searchQuery = (query) => setSearch(query);
  const secondSearchQuery = (query) => setSecondSearch(query);

  return (
      <Row>
        <Dashboard stats={stats}/>
        <div className="data__wrapper">
          <Search searchQuery={searchQuery}/>
          { reports
              ? <DataTable
                  title="Dynamic variables ('v' parameter)"
                  columns={applyCurrencyFormatters(columns)}
                  data={reports}
                  progressPending={loading}
                  customStyles={customStyles}
                  pagination
                  progressComponent={<Circular/>}
                  actions={<Export rows={reports}/>}
                  paginationRowsPerPageOptions={perPageList}
              />
              : <CircularProgress />
          }
        </div>

        <div className="data__wrapper">
          <Search searchQuery={secondSearchQuery}/>
          { secondReports
              ? <DataTable
                  title="Second Dynamic variables ('v2' parameter)"
                  columns={applyCurrencyFormatters(columns)}
                  data={secondReports}
                  progressPending={loading}
                  customStyles={customStyles}
                  pagination
                  progressComponent={<Circular/>}
                  actions={<Export rows={secondReports}/>}
                  paginationRowsPerPageOptions={perPageList}
              />
              : <CircularProgress />
          }
        </div>
      </Row>
  );
};

export default connectTopLine(true, true, DynamicVariables);
