import { PreStyledMainCard } from "../../../../../new_components/cards-data-container";
import {Box, Card, keyframes, styled} from "@mui/material";


import InfiniteCarousel from 'react-leaf-carousel';

const StyledBrandSlider = styled(PreStyledMainCard)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  overflow: "hidden",
}));

const BrandWrap = styled(Box)(({ theme }) => ({
  width: theme.spacing(95),
  height: "fit-content",
  objectFit: "contain",
  borderColor: theme.palette.surface.border.cardImage,
  borderRadius: "10px",
  position: "absolute",
  bottom: -20,
  overflow: "hidden",
}));

const StyledImage = styled("img")(({ theme }) => ({
  width: "100%",
}));

const buttonAnimation = keyframes`
  0% {
    transform: translate(-50%, 250%) rotate(-8deg);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.30);
  }
  25% {
    transform: translate(-50%, 70%) rotate(-8deg);
    box-shadow: 0 0 0 150px rgba(255, 255, 255, 0.30);
  }
  50% {
    transform: translate(-50%, -150%) rotate(-8deg);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.30);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, 300%) rotate(-8deg);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.30);
    opacity: 0.5;
  }
`;

const BrandButtonWrap = styled(Box)(({ theme }) => ({
  zIndex: 1,
  background: theme.palette.background.pureBetsBackground,
  padding: theme.spacing(5, 9.75),
  height: "max-content",
  borderRadius: theme.spacing(3.25),
  borderWidth: 3,
  borderStyle: "solid",
  borderColor: theme.palette.surface.border.white,
  maxWidth: theme.spacing(46.25),

  position: "absolute",
  top: "50%",  // Center vertically
  left: "50%", // Center horizontally
  transform: "translate(-50%, -50%) rotate(-8deg)", // Adjust centering and rotation
  boxShadow: "0px 0px 0px 0px rgba(255, 255, 255, 0.30)", // Start state
  animation: `${buttonAnimation} 5s infinite`, // Adjust time here as needed
}));

const BrandSlider = ({brands, onSlideChange}) => {
  return (
    <StyledBrandSlider>
      <BrandWrap>
        <InfiniteCarousel
            breakpoints={[
              {
                breakpoint: 100,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
              },
            ]}
            slidesToScroll={1}
            slidesToShow={1}
            arrows
            autoCycle
            scrollOnDevice
            cycleInterval={10000}
            pauseOnHover
            onSlideChange={onSlideChange}
        >
        {brands.map(b =>
          <Box>
            <StyledImage alt="pure-bets-pic" src={b.picture} />
            <BrandButtonWrap component="div">{b.icon}</BrandButtonWrap>
          </Box>
        )}
        </InfiniteCarousel>
      </BrandWrap>
    </StyledBrandSlider>
  );
};

export default BrandSlider;